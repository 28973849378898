.contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    align-items: center;
    color: white;
}

.contact h2{ 
    padding: 20px;
    font-family: "Ink Free";
    font-weight: 200;
}

.contactcard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    height: 400px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(20px);
    box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
}

.contactcard:hover{
    transform-origin: top left;
    transform: rotate(0deg);
    animation: drawCard .3s ease-out forwards;
}

@keyframes drawCard {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(50px);
    }

    100% {
        transform: translateY(0px);
    }
  }

.receive {
    position: relative;
    top: 20px;
    font-family: "Ink Free";
    opacity: 0;
}

.show {
    animation: received .3s ease-in forwards;
}

@keyframes received {
    0% {
        opacity: 0;
    }
    
    100% {
        opacity: 100;
    }
}

.contactphoto {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    margin: 20px 0 20px 0;
    box-shadow: 1px 1px 4px 2px rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba(173, 229, 247, 0.466);
}

.contactcard hr{
    width: 250px;
}

.contacts {
    display: block;
    margin-top: 5%
}

.links {
    display: flex;
    padding: 5px;
}

.icon {
    font-size: 12px;
}

.mail {
    content: url('../svg/mail.svg');
}

.git{
    content: url('../svg/git.svg');
}

.resume {
    content: url('../svg/resume.svg')
}

.linkedin {
    content: url('../svg/linkedin.svg')
}

.contacts a {
    padding: 5px 5px 5px 10px;
    letter-spacing: 2px;
}

.contacts a:hover {
    background-color: transparent;
    color: white;
}

