.sidebar{
    margin: 0;
    background-color: #282424;
    width: 150px;
    color: #bbbbbb;
    padding-left: 20px;
    font-size: 20px;
    min-height: 100vh;
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
    font-family: Verdana;
}

.sidebarbtn{
    font-weight: 100;
    font-size: 15px;
    background-color: transparent;
    color: #bbbbbb;
    border: none;
}

.sidebarbtn:hover{
    color: white;
}

hr{
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 140px;
}

ul{
    padding-left: 10px;
    padding-bottom: 5px;
}

li{
    font-weight: bolder;
    font-size: 15px;
}

.files{
    list-style: none;
    color: lightsalmon;
    font-weight: 100;
}

.folders{
    color: lightgreen;
}

.openfolder {
    list-style-image: url('../svg/down.svg');
}

.closedfolder {
    list-style-image: url('../svg/right.svg');
}

.hidden { 
    display: none;
}

.show{
    display:block;
}

.folderhover:hover {
    background-color: #2a2d2e;
}

.files:hover {
    background-color: #2a2d2e;
}

.selectedside {
    background-color: #2a2d2e;
}

@media(max-width: 500px) {
    .sidebar hr {
        width: 90%;
    }

    .folderhover {
        font-size: 11px;
        text-indent: -5px;
    }

    .folders {
        text-indent: -6px;
        padding-right: 2px;
    }

    .files {
        font-size: 12px;
        padding: 5px 2px 0 0;

    }
}