.navbar {
    display: flex;
    min-width: 100vw;
    min-width: fill-available;
    min-width: -webkit-fill-available;
    min-width: -moz-available;
    align-items: center;
    background-color:#403c3c;
    justify-content: center;
    height: 30px;
}

.menu{
    position: absolute;
    left: 10px;
}

a {
    padding: 5px 10px 5px 10px;
    text-decoration: none;
    color: #c4c4c4;
    font-size: 12px;
}

a:hover{
    color:white;
    background-color: #505050;
}

.title{
    color: #c4c4c4;
    font-size: 15px;
}

@media (max-width: 1000px) {
    .title {
        display:none;
    }
}
