.bodycontainer {
    height: 100vh;
    max-width: 100vw;
}

.filebar {
    max-width: 100vw;
    background-color: #2d2d2d;
    height: 30px;
    display:flex;
    flex-direction: row;
    color: #949494;
}

.otherfiles {
    display: flex;
}

.idefile {
    display:flex;
    padding: 10px 10px 10px 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid #252526;
}

.idefile:nth-child(1){
    margin-left: 20px;
}

.ide {
    width:100%;
    height: 100vh;
}

.selected {
    background-color:  #201c1c;
}

.closebtn {
    font-size: 12px;
    margin-left: 10px;
    background-color: transparent;
    border: none;
    color: #949494;
}

@media(max-width: 500px) {
    .filebar{
        display:none;
    }
}