.aboutme{
    display:flex;
    height: 90vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title-header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 50px;
    position: relative;
    top: -50px;
    border-radius: 10px;
    font-size: 50px;
    background-color: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(20px);
    box-shadow: 0 10px 10px rgba(0,0,0,0.3);
    color: white;
}

.title-header span {
    padding: 5px;
}

.cards {
    display: flex;
    justify-content: space-evenly;
}

.left, .middle, .right{
    display: flex;
    justify-content: center;
    align-items:center;
    border-radius: 20px;
    width: 400px;
    height: 500px;
    color:white;
    margin: 20px;
    background-color: rgba(255, 255, 255, 0.02);
    box-shadow: 0 4px 10px rgba(0,0,0,0.3);
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.cardtitle {
    position: relative;
    display: inline-block;
}

.cardtitle::before, .cardtitle::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    background-color: white;
    transition: all 0.5s ease;
}

.left .cardtitle::before {
    top: -10px;
    left: -60%;
}

.left .cardtitle::after {
    bottom: -10px;
    right: -61px;
}

.middle .cardtitle::before {
    top: -10px;
    left: -15%;
}

.middle .cardtitle::after {
    bottom: -10px;
    right: -12%;
}

.right .cardtitle::before {
    top: -10px;
    left: -40px;
}

.right .cardtitle::after {
    bottom: -10px;
    right: -29px;
}

.left:hover .cardtitle::before, .left:hover .cardtitle::after {
    width: 200px;
}

.middle:hover .cardtitle::before, .middle:hover .cardtitle::after {
    width: 200px;
}

.right:hover .cardtitle::before, .right:hover .cardtitle::after {
    width: 200px;
}

.front, .back {
    position: absolute;
    backface-visibility: hidden;
}

.back {
    transform: rotateY(180deg);
}

.flipped {
    transform: rotateY(180deg);
}

section {
    padding: 20px 0 10px 0;
    width:400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backcontent {
    display: flex;
    align-items: center;
    width: 400px;
    height: 500px;
}

.leftcontent {
    flex-direction: row;
}

.leftcontent h4 {
    width:fit-content;
    border-bottom: 1px solid white;
    font-size: 20px;
    margin-bottom: 10px;
}

.leftcontent hr {
    width: 1px;
    height: 80%;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.leftcontent.backcontent img {
    padding: 5px;
}

.middlecontent {
    flex-direction: column;
}

.middlecontent section {
    height:50vh;
}

.middlecontent hr{
    width: 80%;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.middlecontent section{
    box-sizing: border-box;
    padding: 15px;
}

.job {
    display: flex;
    justify-content: center;
}

.job p{
    font-size: 12px;
    padding: 5px;
    word-break: break-all;
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
}

.job p:nth-of-type(odd){
    font-style: italic;
    font-weight: normal;
}

.jobdesc{
    display: flex;
    width: 80%;
    padding-top: 10px;
    font-size: 13px;
    text-align: center;
}

.jobskills {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
}

.jobskills img {
    padding-right: 10px;
}

.jobskills p{
    padding: 5px 0 0 5px;
}

.rightcontent{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.righttext {
    width: 90%;
}

.hobby h4{
    padding: 20px;
}

.hobby li{
    display: inline-block;
    padding: 5px 1px 0 1px;
}

.hobby img {
    border-radius: 20px;
    height: 370px;
}

input {
    background-color: transparent;
    border: none;
}

input:hover{
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.05);
    cursor: help;
}

@media(max-width: 1450px) {
    .aboutme {
        display: flex;
        justify-content: start;
        overflow-y: scroll;
    }

    .title-header{
        top: 0;
    }
    .cards {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
}
