.projectcontent {
    width: 100%;
    height: 100vh;
}

.projectspage {
    overflow-y: scroll;
}

.projectspage::-webkit-scrollbar{
    background-color: #201c1c;
    width: 10px;
}

.projectspage::-webkit-scrollbar-track{
    box-shadow: inset 0 0 3px rgba(255,255,255,0.1);
}

.projectspage::-webkit-scrollbar-thumb{
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
}

.project {
    display: flex;
    padding: 40px;
    align-items: center;
    justify-content: center;
}

.display {
    display: flex;
    width: 50%;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    text-align: center;
    justify-content: center;
}

.display img {
    width: 300px;
    padding: 10px;
}

figcaption {
    color: white;
    padding-bottom: 5px;
}

.project hr{
    width: 1px;
    height: 20vh;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0 50px 0 50px;
}

.aboutproj{
    width: 30%;
    word-wrap: break-word;
    padding: 5px 0 5px 0;
    background-color: rgba(255, 255, 255, 0.05);
    text-align: center;
    color: white;
}

.aboutproj p {
    padding: 5px;
}

.projtitle{
    justify-content: center;
    margin: 5px;
}


