*{
    margin: 0;
    padding: 0;
    font-family: cascade mono;
    cursor: default;
}

body {
    background-color: #201c1c;
    overflow: hidden;
}

body::-webkit-scrollbar{
    display:none;
}

.bars {
    display: flex;
}

.filepath {
    color: #949494;
    font-size: 15px;
    padding: 5px 10px 5px 20px;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.import {
    padding-left: 20px;
    font-size: 20px;
    color:white;
}

.pink {
    color:#c586c0;
}

.blue {
    color: #9cdcfe;
}

.orange {
    color:#ce9178;
}

.gray {
    color: #949494; 
    padding-right: 10px;
}