.homepage {
    width: 100vw;
    height: 100vh;
    font-size: 12px;
}

.welcome {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.circle {
  display: flex;
  justify-content: center;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 2px solid #EEAAD3;
  overflow: hidden;
  margin-right: 50px;
  box-shadow: 0 0 10px 10px rgba(197, 134, 192, 0.3);
}

.welcomemsg span{
    display:inline-block;
    position: relative;
    font-size: 60px;
    color: white;
    right: 10px;
}


.welcomemsg p{
    font-size: 20px;
    color: white;
}

.modalcomp {
  position: absolute;
  z-index: 1;
  width: 20%;
  height: 30px;
  background-color: black;
  top: 120px;
  left: 150px;
  color: white;
}

.helpclosebtn{
  position: relative;
  float:right;
  top: 3px;
  background-color: transparent;
  color: white;
  border:none;
}

.helpclosebtn:hover{
  color: gray;
}

.arrow {
  position: absolute;
  top:-15px;
  width: 60px;
}

.info {
  position: absolute;
  top: 10px;
  left: 20px;
  font-family: "Ink Free";
}

.hide{
  display:none;
}

.rollingtxt {
  display: block;
  height: 25px;
  position: relative;
  width: 260px;
  overflow: hidden;
  color: white;
  font-size: 20px;
}

.rollingtxt span{
  font-size: 20px;
  top: 2px;
  width: 400px;
  left: 55px;
  display: block;
  text-align: left;
  transform: translateY(-0px);
  animation: rollin-txt 8s infinite;
  color: #EEAAD3;
}

@keyframes rollin-txt {
  0% {
    transform: translateY(-25px);
  }
  10% {
    transform: translateY(-25px);
  }
  20% {
    transform: translateY(-48px);
  }
  30% {
    transform: translateY(-48px);
  }
  50% {
    transform: translateY(-71px);
  }
  60% {
    transform: translateY(-71px);
  }
  70% {
    transform: translateY(-94px);
  }
  80% {
    transform: translateY(-94px);
  }
  90% {
    transform: translateY(-117px);
  }
  100% {
    transform: translateY(-117px);
  }
}

@media(max-width: 1450px) {
  .homecontent{
    overflow-x: hidden;
  }

  .welcome{
    display: flex;
    flex-direction: column;
    align-content: center;
  }
}

@media(max-width: 500px) {
  .modal {
    display: none;
  }

  .homepage {
    max-width: 90vw;
  }

  .welcome {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .circle {
    width: 200px;
    height: 200px;
    margin: 0;
  }

  .welcomemsg span {
    font-size: 45px;
    right: 0;
  }
  
  .rollingtxt, .rollingtxt span {
    font-size: 15px;
    width: 200px;
  }

  .rollingtxt {
    padding-left: 2px;
  }

  .rollingtxt span{
    height: 15px;
    padding-bottom: 10px;
    left: 45px;
    top: 0;
  }

  @keyframes rollin-txt {
    0% {
      transform: translateY(-15px);
    }
    10% {
      transform: translateY(-15px);
    }
    20% {
      transform: translateY(-41px);
    }
    30% {
      transform: translateY(-41px);
    }
    50% {
      transform: translateY(-66px);
    }
    60% {
      transform: translateY(-66px);
    }
    70% {
      transform: translateY(-92px);
    }
    80% {
      transform: translateY(-92px);
    }
    90% {
      transform: translateY(-117px);
    }
    100% {
      transform: translateY(-117px);
    }
  }
}